import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { S25DeleteComponent } from "./s25.delete.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";

@NgModule({
    declarations: [S25DeleteComponent],
    imports: [CommonModule, S25ToggleButtonModule, S25LoadingInlineModule],
    providers: [S25DeleteComponent],
    exports: [S25DeleteComponent],
})
export class S25DeleteModule {
    constructor() {}
}
