import {
    ChangeDetectorRef,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    QueryList,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { Throttle } from "../../decorators/debounce.decorator";
import { S25DndSortableItemDirective } from "./s25.dnd.sortable.item.directive";

export interface SortableEvent {
    "s25-dnd-sortable-item--index": number;
    "s25-dnd-sortable-item--belongsTo"?: any[];
    [key: string]: any;
}

@Directive({ selector: "[s25-ng-dnd-sortable]" })
export class S25DndSortableDirective {
    @Input() items: any[] = [];
    @Input()
    @HostBinding("class.sortable")
    sortable = true; // Set to false to disable sorting

    @Output() orderChanged = new EventEmitter<void>();

    @ContentChildren(S25DndSortableItemDirective) itemDirectives: QueryList<S25DndSortableItemDirective>;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    draggedIndex: number;
    @HostBinding("class.s25-dnd-sortable--dragging") dragging = false;
    @HostBinding("class.s25-dnd-sortable--dragging-keyboard") keyboardDragging = false;
    @HostBinding("attr.role") ariaRole = "list"; // Allow description

    @HostListener("dragstart", ["$event"])
    @HostListener("dragenter", ["$event"])
    @HostListener("dragend", ["$event"]) // Actually does not fire after node has been moved
    @HostListener("dragover", ["$event"])
    onDragEvent(event: Event & SortableEvent) {
        if (!this.sortable || !this.belongsHere(event)) return; // Skip if event is not for this list
        switch (event.type) {
            case "dragstart":
                return this.startDrag(this.getIndex(event));
            case "dragenter":
                return this.attemptReorder(event, this.draggedIndex, this.getIndex(event));
            case "dragend":
                return this.cancelDrag();
            case "dragover": // Allow dropping if item is already moved in onDragEnter
                if (this.draggedIndex === this.getIndex(event)) event.preventDefault();
                return;
        }
    }

    @HostListener("keydown.enter", ["$event"])
    @HostListener("keydown.space", ["$event"])
    @HostListener("keydown.arrowup", ["$event"])
    @HostListener("keydown.arrowleft", ["$event"])
    @HostListener("keydown.arrowdown", ["$event"])
    @HostListener("keydown.arrowright", ["$event"])
    @HostListener("keydown.tab", ["$event"])
    @HostListener("keydown.shift.tab", ["$event"])
    onKeyEvent(event: KeyboardEvent & SortableEvent) {
        if (!this.sortable || !this.belongsHere(event)) return; // Skip if event is not for this list
        switch (event.key) {
            case "Enter":
            case "Space":
                return this.toggleKeyboardDrag(event);
            case "ArrowUp":
            case "ArrowLeft":
                return this.keyboardIncrement(event, -1);
            case "ArrowDown":
            case "ArrowRight":
                return this.keyboardIncrement(event, 1);
            case "Tab":
                if (this.dragging) event.preventDefault();
                return;
        }
    }

    toggleKeyboardDrag(event: KeyboardEvent & SortableEvent) {
        if (this.dragging !== this.keyboardDragging) return; // Skip if mouse dragging
        if (!this.dragging) this.startDrag(this.getIndex(event));
        else {
            this.attemptReorder(event, this.draggedIndex, this.getIndex(event));
            this.cancelDrag();
        }
        this.keyboardDragging = this.dragging;
        event.preventDefault();
    }

    startDrag(index: number) {
        this.draggedIndex = index;
        this.dragging = true;
        this.announce(`Picked up item ${index + 1} of ${this.items.length}`, true);
    }

    cancelDrag() {
        this.dragging = false;
        this.keyboardDragging = false;
        this.announce(`Dropped item at position ${this.draggedIndex + 1} of ${this.items.length}`, true);
    }

    keyboardIncrement(event: any, direction: -1 | 1) {
        if (!this.keyboardDragging) return;
        this.attemptReorder(event, this.draggedIndex, this.draggedIndex + direction);
        event.preventDefault(); // Prevent scrolling
    }

    attemptReorder(event: SortableEvent, from: number, to: number) {
        if (!this.dragging) return;
        if (from == null || to == null) return; // Skip if invalid indices
        if (event?.fromElement?.closest("[s25-ng-dnd-sortable-item]") === this.elementRef.nativeElement) return; // Ignore internal dragging
        // Clamp indices to bounds
        from = S25Util.clamp(from, 0, this.items.length - 1);
        to = S25Util.clamp(to, 0, this.items.length - 1);
        if (to === from) return; // No need to move if same
        // All is good
        this.reorderItem(event, from, to);
    }

    @Throttle(100)
    reorderItem(event: any, from: number, to: number) {
        const item = this.items.splice(from, 1)[0];
        this.items.splice(to, 0, item);
        this.draggedIndex = to;
        this.afterReorder(event);
    }

    afterReorder(event: any) {
        this.changeDetector.detectChanges();
        if (this.keyboardDragging) {
            const test = this.itemDirectives.toArray().find((item) => {
                return item.index === this.draggedIndex && (!item.belongsTo || item.belongsTo === this.items);
            });
            test.elementRef.nativeElement.focus(); // Focus is lost, so get it back
            test.elementRef.nativeElement.scrollIntoView();
        }
        this.orderChanged.emit();
        this.announce(`Moved item to position ${this.draggedIndex + 1} of ${this.items.length}`, true);
    }

    getIndex(event: SortableEvent) {
        return event["s25-dnd-sortable-item--index"];
    }

    belongsHere(event: SortableEvent) {
        const belongsTo = event["s25-dnd-sortable-item--belongsTo"];
        const isItem = event["s25-dnd-sortable-item"];
        return isItem && (belongsTo == null || belongsTo === this.items);
    }

    announce(text: string, assertive?: boolean) {
        // Use aria-live to speak a string to screen readers
        const elem = document.createElement("div");
        elem.setAttribute("aria-live", assertive ? "assertive" : "polite");
        elem.style.position = "absolute";
        elem.style.opacity = "0";
        elem.innerText = text;
        this.elementRef.nativeElement.appendChild(elem);
        setTimeout(() => {
            this.elementRef.nativeElement.removeChild(elem);
        }, 1000);
    }
}
