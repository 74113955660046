//@author: kate
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AlertService } from "../../services/alert.service";

@TypeManagerDecorator("s25-delete")
@Component({
    selector: "s25-delete",
    template: ` <div>
        <label *ngIf="this.hasToggle" for="deleteToggle" class="ngBold c-margin-bottom--none">{{
            this.toggleLabel
        }}</label>
        <s25-toggle-button
            *ngIf="this.hasToggle"
            name="deleteToggle"
            (modelValueChange)="this.onChange()"
            [modelValue]="this.enableDelete"
            [trueLabel]="this.trueLabel"
            [falseLabel]="this.falseLabel"
        ></s25-toggle-button>
        <p *ngIf="this.enableDelete" class="ngFinePrint c-margin-bottom--quarter">{{ this.warningMsg }}</p>
        <button
            *ngIf="this.enableDelete || !this.hasToggle"
            class="aw-button aw-button--primary c-margin-top--single"
            (click)="this.delete()"
        >
            {{ this.buttonText }}
        </button>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DeleteComponent implements OnInit {
    @Input() hasToggle: boolean = true; // pass in false if toggle is not necessary
    @Input() deleteFn: any; // see example below
    @Input() cancelFn: any; // optional; only if something needs to happen on cancel of alert message
    @Input() confirmMsg: string; // message that shows in the confirmation alert
    @Input() textType: number = null; //optional, if text needs to depend on object type
    @Input() warningMsg?: string = "This action is permanent and will result in loss of information."; //optional, if text needs to depend on object type

    // deleteFn example; make sure there is error handling and loading, and add <s25-loading data-model="{text:'Loading...'}"></s25-loading> to template
    // deleteEvent: function() {
    //     S25LoadingApi.init($scope);
    //     EventService.deleteEvent(self.itemId).then(function(resp) { //delete event
    //         if(resp.success) { //is success
    //             S25LoadingApi.destroyYieldRendering($scope);
    //             StateService.closeCurrent();
    //         } else { //error, display msg
    //             alert("There was a problem with your request. The event was not deleted.");
    //             S25LoadingApi.destroyYieldRendering($scope);
    //         }
    //     }, function() { //error, display msg
    //         alert("There was a problem with your request. The event was not deleted.");
    //         S25LoadingApi.destroyYieldRendering($scope);
    //     });
    // }

    enableDelete: boolean = false;
    confirmString: string;
    buttonText: string = "Delete";
    toggleLabel: string = "Delete Mode";
    trueLabel: string = "On";
    falseLabel: string = "Off";

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    delete() {
        AlertService.confirm(this.confirmMsg).then((confirmed: boolean) => {
            if (confirmed) {
                this.deleteFn();
            } else if (this.cancelFn) {
                this.cancelFn();
            }
        });
    }

    onChange() {
        this.enableDelete = !this.enableDelete;
        this.cd.detectChanges();
    }

    ngOnInit() {
        this.enableDelete = false;
        if (this.textType && this.textType === 1) {
            this.buttonText = "Delete Event";
        }
    }
}
