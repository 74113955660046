import { Directive, ElementRef, HostBinding, HostListener, Input } from "@angular/core";

@Directive({ selector: "[s25-ng-dnd-sortable-item]" })
export class S25DndSortableItemDirective {
    @Input() index: number; // Has to be unique in sortable
    @Input() belongsTo?: any[]; // Only needed for nested sortables

    constructor(public elementRef: ElementRef) {}

    @HostBinding("class.dragging") dragging = false; // Add class when dragging with mouse
    @Input() @HostBinding("draggable") draggable = true; // Make item draggable
    @HostBinding("tabindex") tabIndex = 0; // Make item tabbable
    @HostBinding("attr.role") ariaRole = "listitem"; // Allow description
    @HostBinding("attr.aria-roledescription") // Description for screen readers
    ariaRoleDescription = "Draggable item. Press enter to grab or drop."; // Has instructions because I could not get aria-describedby to work?

    // Add data to event
    // Wanted to use @HostBinding for this, but the test runner was complaining about custom attributes not existing
    @HostListener("dragstart", ["$event"])
    @HostListener("dragend", ["$event"])
    @HostListener("dragenter", ["$event"])
    @HostListener("dragover", ["$event"])
    @HostListener("keydown.enter", ["$event"])
    @HostListener("keydown.space", ["$event"])
    @HostListener("keydown.arrowup", ["$event"])
    @HostListener("keydown.arrowdown", ["$event"])
    @HostListener("keydown.arrowleft", ["$event"])
    @HostListener("keydown.arrowright", ["$event"])
    @HostListener("keydown.tab", ["$event"])
    @HostListener("keydown.shift.tab", ["$event"])
    onEvent(event: any) {
        if (event.type === "keydown" && event.target !== this.elementRef.nativeElement) return; // Only act on keyboard events originating on item
        if (event.type === "dragstart") this.dragging = true;
        if (event.type === "dragend") this.dragging = false;
        event["s25-dnd-sortable-item--index"] = this.index;
        event["s25-dnd-sortable-item--belongsTo"] = this.belongsTo;
        event["s25-dnd-sortable-item"] = true;
    }
}
