//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25DatepickerComponent } from "./s25.datepicker.component";
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { S25MultiDatepickerComponent } from "./s25.multi.datepicker.component";
import { S25OccurrenceDatepickerComponent } from "./s25.occurrence.datepicker.component";

@NgModule({
    declarations: [S25DatepickerComponent, S25MultiDatepickerComponent, S25OccurrenceDatepickerComponent],
    imports: [CommonModule, FormsModule, NgbModule, NgbDatepickerModule],
    providers: [S25DatepickerComponent, S25MultiDatepickerComponent, S25OccurrenceDatepickerComponent],
    exports: [S25DatepickerComponent, S25MultiDatepickerComponent, S25OccurrenceDatepickerComponent],
})
export class S25DatepickerModule {
    constructor() {}
}
