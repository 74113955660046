import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { UserprefService } from "../../services/userpref.service";
import { DaysOfWeekPref, PreferenceService } from "../../services/preference.service";

export interface DayPreferencesI {
    [key: string]: {
        value: {
            days: {
                day: {
                    name: string;
                    position: string;
                    show: "true" | "false";
                    dow: number | string;
                }[];
            };
        };
    };
}

@TypeManagerDecorator("s25-ng-day-chooser")
@Component({
    selector: "s25-ng-day-chooser",
    template: `
        <s25-ng-multiselect-popup
            [modelBean]="multiselectBean"
            [ngClass]="{ ngRelative: !isInline }"
        ></s25-ng-multiselect-popup>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DayChooserComponent implements OnInit {
    @Input() isInline: boolean;
    @Input() skipDaysPref: boolean;
    @Input() daysAction?: Function;
    @Output() onChange = new EventEmitter<DaysOfWeekPref>();

    multiselectBean: MultiselectModelI = {
        noSort: true,
        items: [],
        selectedItems: [],
        title: "Days",
        hasSelectAll: false,
        hasSelectNone: false,
        hasFilter: false,
    };

    private static prefName = "25L_availability_grid_weekdays";

    ngOnInit() {
        // Initialize all to unchecked while preferences are loading
        this.setMultiselectBean(new Array(7).fill(false));
        this.multiselectBean.onChange = this.update;

        if (!this.skipDaysPref) {
            // Load preferences
            PreferenceService.getPreferences([S25DayChooserComponent.prefName]).then((preferences: DayPreferencesI) => {
                const dayPreferences = preferences?.[S25DayChooserComponent.prefName]?.value?.days?.day;
                if (dayPreferences) this.setMultiselectBean(dayPreferences.map((p) => p.show === "true"));
            });
        }
    }

    setMultiselectBean(preferences: boolean[]) {
        const items = preferences.map((checked, i) => ({
            checked,
            itemName: S25Util.date.getDayName(i),
            itemId: i,
            dow: i,
        }));
        this.multiselectBean.items = items;
        this.multiselectBean.selectedItems = items.filter((item) => item.checked);
    }

    update = () => {
        const daysPrefXml = this.multiselectBean.items.map((item) => ({
            _name: item.itemName,
            _position: String(item.itemId),
            _show: String(item.checked),
        }));
        const daysPref = this.multiselectBean.items.map((item) => ({
            name: item.itemName,
            position: String(item.itemId),
            show: String(item.checked),
            dow: item.itemId,
        }));
        let daysOfWeekPref = { days: { day: daysPrefXml } };
        let daysOfWeek = { days: { day: daysPref } } as DaysOfWeekPref;

        UserprefService.getLoggedIn().then((isLoggedIn) => {
            if (!this.skipDaysPref && isLoggedIn)
                PreferenceService.setPreference(S25DayChooserComponent.prefName, daysOfWeekPref);
            this.daysAction?.(daysOfWeek); //pass json object to caller
            this.onChange.emit(daysOfWeek);
        });
    };
}
