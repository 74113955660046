import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@TypeManagerDecorator("s25-ng-multi-datepicker")
@Component({
    selector: "s25-ng-multi-datepicker",
    template: `
        <div *ngIf="this.init" class="s25MultiDatePicker">
            <s25-datepicker
                class="S25NgDatepicker"
                [inputLabel]="this.inputLabel"
                [inputId]="this.inputId"
                [(modelValue)]="this.datePickerModel"
                [inline]="true"
                [multipleDate]="true"
                (modelValueChange)="onChange()"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [isDateSelected]="isDateSelected"
            >
            </s25-datepicker>
            <span class="ngBold ngRed" *ngIf="errorMsg && errorMsg != 'true' && errorMsg != true">{{
                this.errorMsg
            }}</span>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25MultiDatepickerComponent implements OnInit {
    @Input() onSelect: Function;
    @Input() selectedDates: Date[] = [];
    @Input() firstDate: Date = new Date();
    @Input() includeFirstDate: boolean = true;
    @Input() apiBean: any = {};
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() validateDate?: (date: Date) => boolean | string;
    @Output() apiBeanChange = new EventEmitter();
    @Output() datesChange = new EventEmitter();

    //Passing these through
    @Input() inputLabel: string = undefined;
    @Input() inputId: string = undefined;
    @Input() prefType: string;
    @Input() onClose: Function;

    init = false;
    datePickerModel: any;
    selectedDateHash: any = [];
    errorMsg: string;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.selectedDates = S25Util.array.forceArray(this.selectedDates);
        this.selectedDates.length > 1 &&
            this.selectedDates.sort((a, b) => {
                return Number(a) - Number(b);
            });

        for (let dt of this.selectedDates) {
            dt = S25Util.date.getDate(dt);
            this.selectedDateHash[Number(dt)] = dt;
        }

        S25Util.extend(this.apiBean, {
            getOccurrences: () => {
                return this.selectedDates;
            },
            setOccurrences: (dates: Date[]) => {
                this.selectedDates = dates;
                this.refreshSelected();
            },
            getProfileModel: () => {
                return {
                    occurrences: this.selectedDates,
                    profileCode: "adHoc",
                    type: "adhoc",
                    initDates: this.firstDate,
                };
            },
            getFirstDate: () => {
                return this.firstDate;
            },
            addDate: this.addDate,
            removeDate: this.removeDate,
        });

        this.refreshSelected();
        this.init = true;
        this.cd.detectChanges();
    }

    onChange() {
        const date = S25Util.date.getDate(this.datePickerModel);
        const d = this.selectedDateHash[Number(date)];
        const isSelected = !S25Util.isUndefined(d);
        if (this.onSelect) {
            this.onSelect({ date, isSelected });
            this.refreshSelected(); //reformat the datePickerModel so that all occurences show on the date picker
        } else {
            //Default behavior. Add unselected dates, remove previously selected dates.
            if (!isSelected) this.addDate(this.datePickerModel);
            else this.removeDate(this.datePickerModel);
        }

        this.apiBeanChange.emit(this.apiBean);
    }

    @Bind
    addDate(date: Date, isFirst?: boolean) {
        this.errorMsg = null;
        date = S25Util.date.getDate(date);
        let d = this.selectedDateHash[Number(date)];
        if (S25Util.isUndefined(d)) {
            this.errorMsg = this.validateDate ? S25Util.coalesce(this.validateDate(d), true) : true;
            if (S25Util.toBool(this.errorMsg) == true) {
                isFirst ? this.selectedDates.unshift(date) : this.selectedDates.push(date);
                this.selectedDateHash[Number(date)] = date;
                this.refreshSelected();
                this.datesChange.emit();
            }
        }
        this.apiBeanChange.emit(this.apiBean);
    }

    @Bind
    removeDate(date: Date) {
        this.errorMsg = null;
        date = S25Util.date.getDate(date);
        date = this.selectedDateHash[Number(date)];
        if (S25Util.isDefined(date)) {
            this.selectedDates.splice(this.selectedDates.map(Number).indexOf(Number(date)), 1);
            this.selectedDateHash[Number(date)] = null;
            this.refreshSelected();
            this.apiBeanChange.emit(this.apiBean);
            this.datesChange.emit();
        }
    }

    refreshSelected() {
        //reformat the datePickerModel so that all occurences show on the date picker
        let tmpFirst: Date = new Date(+this.firstDate);
        let tmpOccDates: any = [];
        this.includeFirstDate && this.firstDate && this.addDate(this.firstDate);

        S25Util.copy(tmpOccDates, this.selectedDates || []);
        this.datePickerModel = {
            date: tmpFirst || tmpOccDates[0] || new Date(),
            occurrencesDates: tmpOccDates,
            showToday: false,
        };
    }

    @Bind
    isDateSelected(ngbDate: NgbDate) {
        const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
        return !!this.selectedDateHash[Number(date)];
    }
}
